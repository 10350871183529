import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

const BlogIndex = () => {
  const data = useStaticQuery(graphql`
    query BlogPosts {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: [DESC] }
        limit: 5
      ) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              author
              date
              name
              permalink
            }
            timeToRead
          }
        }
      }
    }
  `)

  return (
    <>
      <h2>Latest Posts</h2>
      {data?.allMarkdownRemark?.edges.map((post: any) => (
        <div key={post.node.frontmatter.permalink}>
          <Link to={post.node.frontmatter.permalink}>
            <h3>{post.node.frontmatter.name}</h3>
          </Link>
          <h4>
            Posted by {post.node.frontmatter.author} on{" "}
            {post.node.frontmatter.date}
          </h4>
          <p>{post.node.excerpt}</p>
        </div>
      ))}
    </>
  )
}

export default BlogIndex
